// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-matematika-js": () => import("./../../../src/pages/matematika.js" /* webpackChunkName: "component---src-pages-matematika-js" */),
  "component---src-pages-propis-1-js": () => import("./../../../src/pages/propis-1.js" /* webpackChunkName: "component---src-pages-propis-1-js" */),
  "component---src-pages-propis-2-js": () => import("./../../../src/pages/propis-2.js" /* webpackChunkName: "component---src-pages-propis-2-js" */),
  "component---src-pages-propis-eng-js": () => import("./../../../src/pages/propis-eng.js" /* webpackChunkName: "component---src-pages-propis-eng-js" */),
  "component---src-pages-propis-englet-js": () => import("./../../../src/pages/propis-englet.js" /* webpackChunkName: "component---src-pages-propis-englet-js" */),
  "component---src-pages-rus-perehod-js": () => import("./../../../src/pages/rus-perehod.js" /* webpackChunkName: "component---src-pages-rus-perehod-js" */),
  "component---src-pages-ruskids-js": () => import("./../../../src/pages/ruskids.js" /* webpackChunkName: "component---src-pages-ruskids-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

